<template>
  <div class="search-logging-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_LOGGING_HEADER_COMPONENT_FIELD_NAME_VALIDATE_DATE_RANGE"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_LOGGING_HEADER_COMPONENT_LABEL_DATE_RANGE")}}</label>
              <div class="input-group1">
                <date-range-picker
                  v-model="searchData.dateRange"
                  :locale-data="dateRangeOption.localeData"
                  :always-show-calendars="dateRangeOption.alwaysShowCalendars"
                  :append-to-body="dateRangeOption.appendToBody"
                  :ranges="dateRangeOption.showRanges"
                  :auto-apply="dateRangeOption.autoApply"
                ></date-range-picker>
              </div>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_LOGGING_HEADER_COMPONENT_FIELD_NAME_KEYWORD"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_LOGGING_HEADER_COMPONENT_LABEL_KEYWORD')"
              type="text"
              v-model="searchData.keyword"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

      <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_LOGGING_HEADER_COMPONENT_FIELD_NAME_TYPE"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_LOGGING_HEADER_COMPONENT_LABEL_TYPE')"
              type="text"
              v-model="searchData.type"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_LOGGING_HEADER_COMPONENT_FIELD_NAME_WALLET_ADDRESS"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_LOGGING_HEADER_COMPONENT_LABEL_WALLET_ADDRESS')"
              type="text"
              v-model="searchData.wallet_address"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>
      </CRow>

    </ValidationObserver>
    <CRow class="mt-2 form-actions">
      <CCol md="6"></CCol>
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-reset"
          @click="resetForm()"
        >{{$t("SEARCH_LOGGING_HEADER_COMPONENT_RESET_BUTTON")}}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-search"
          @click="search()"
        >{{$t("SEARCH_LOGGING_HEADER_COMPONENT_SEARCH_BUTTON")}}</CButton>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import moment from 'moment';
import { Constants } from '@/constants';
import stringHelper from '@/utils/string-helper';
import DateRangePicker from 'vue2-daterange-picker';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';
export default {
  name: 'SearchLoggingHeader',
  components: {
    DateRangePicker,
  },
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterLoggings) || this.getDefaultValues(),
      dateRangeOption: {
        alwaysShowCalendars: true,
        appendToBody: false,
        showRanges: false,
        autoApply: false,
        localeData: {
          firstDay: 1,
          format: 'yyyy/mm/dd',
          direction: 'ltr',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
        },
      },
      dateTimePickerFormat: Constants.dateTimePickerFormat,
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        dateRange: {
          startDate: null,
          endDate: null,
        },
        keyword: '',
        type: '',
        wallet_address: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();
      this.search();
    },
    async search() {
      this.searchData.dateRange.startDate = dateTimeHelper.startOfDate(this.searchData.dateRange.startDate);
      this.searchData.dateRange.endDate = dateTimeHelper.startOfDate(this.searchData.dateRange.endDate);
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterLoggings, this.searchData);
      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  }
}
</script>
<style lang="scss">
.search-logging-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
        background: #3b4b7e;
      }

      &.btn-reset {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }

  .vue-daterange-picker {
    width: 100%;
    .daterangepicker {
      position: absolute;
      top: 35px;
      left: 0;
      transform: unset;
      padding: 0;
      z-index: 99;
      &::after,
      &::before {
        left: 80px;
        right: unset;
      }
      .calendars-container {
        flex-wrap: wrap;
      }
    }
    .fa.fa-calendar {
      display: none;
    }
  }
}
</style>
