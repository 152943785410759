var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.id))])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.type))])]}},{key:"wallet_address",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(_vm._f("truncate")(item.wallet_address,30)))])]}},{key:"message",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("truncate")(item.message,30)))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,_vm.dateFormat)))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_c('CButton',{on:{"click":function($event){return _vm.getDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]),_c('CButton',{on:{"click":function($event){return _vm.openModal(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-del-user.svg"),"alt":""}})])],1)]}}])})],1),_c('CModal',{attrs:{"id":"deleteModal","show":_vm.warningModal,"centered":true},on:{"update:show":function($event){_vm.warningModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CButtonClose',{on:{"click":function($event){_vm.warningModal = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"cancel-btn",attrs:{"color":"dark","variant":"outline"},on:{"click":function($event){_vm.warningModal = false}}},[_vm._v(_vm._s(_vm.$t('DELETE_LOGGING_MODAL_BUTTON_CANCEL')))]),(_vm.isAllowedDelete)?_c('CButton',{staticClass:"delete-btn",on:{"click":function($event){return _vm.deleteLogging(_vm.id)}}},[_vm._v(_vm._s(_vm.$t('DELETE_LOGGING_BUTTON_DELETE')))]):_vm._e()]},proxy:true}])},[_c('div',{},[_c('div',{staticClass:"pb-4 tit-dele"},[_c('p',[_vm._v(_vm._s(_vm.$t('DELETE_LOGGING_MODAL_TITLE')))])]),_c('div',{staticClass:"text-modal",domProps:{"innerHTML":_vm._s(_vm.$t('DELETE_LOGGING_MODAL_CONTENT'))}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }